<template>
  <v-dialog v-model="cambiarEstado" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        x-large
        :color="getSituacionIcon(estado.estado).color"
        outlined
        v-bind="attrs"
        v-on="noClick ? {} : on"
        :ripple="!noClick"
        class="d-flex pa-4 text-center align-center justify-center text-button"
      >
        <v-icon left>{{ getSituacionIcon(estado.estado).icon }}</v-icon>
        {{ estado.estado }}
        <small v-if="fechaCambio"
          >~ {{ timeAgo(parseDate(fechaCambio, true)) }}</small
        >
      </v-btn>
    </template>
    <cambiar-estado
      @reload="$emit('reload')"
      @close="cambiarEstado = false"
      :estudio="idEstudio"
      :tipo="tipo"
    />
  </v-dialog>
</template>

<script>
import { timeAgo, parseDate, getSituacionIcon } from "@/utils/index.js";

export default {
  props: {
    estado: Object,
    fechaCambio: String,
    idEstudio: Number,
    tipo: String,
    noClick: Boolean,
  },
  components: {
    CambiarEstado: () => import("../components/CambiarEstado.vue"),
  },
  data() {
    return {
      cambiarEstado: false,
    };
  },
  methods: {
    timeAgo,
    parseDate,
    getSituacionIcon,
  },
};
</script>

<style></style>
